// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line object-curly-newline
import {ref, computed, watch, onMounted} from '@vue/composition-api'
import store from '@/store'
import router from "@/router";
import _ from "lodash";

export default function useActivityLogApi() {
    // Use toast
    const toast = useToast()
    // Table Handlers
    const refActivityLogListTable = ref(null)
    const perPage = ref(localStorage.getItem('activityLogPerPage') ?? 10)
    const totalActivityLogs = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 15, 20, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const isLoading = ref(true)
    const activityLogData = ref(null)

    /** Filter */
    const userList = ref(null)
    const contentTypeList = ref(null)
    const actionList = ref(null)

    const userFilter = ref(null)
    const contentTypeFilter = ref(null)
    const actionFilter = ref(null)

    const noRefresh = ref(true)

    const tableColumns = [
        {key: 'id', sortable: true},
        {key: 'user', sortable: false},
        {key: 'description', sortable: true},
        {key: 'content_type', sortable: true},
        {key: 'content_id', sortable: true},
        {key: 'created_at', sortable: true},
        {key: 'actions', sortable: false},
    ]

    const dataMeta = computed(() => {
        const localItemsCount = refActivityLogListTable.value ? refActivityLogListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalActivityLogs.value,
        }
    })

    const refetchData = () => {
        localStorage.setItem('activityLogPerPage', perPage.value)
        refActivityLogListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery, userFilter, contentTypeFilter, actionFilter], () => {
        refetchData()
    })


    // ------------------------------------------------
    // Get Build Error Analysis List
    // ------------------------------------------------
    const fetchActivityLogs = (ctx, callback) => {
        let sort = isSortDirDesc.value ? `-${sortBy.value}` : sortBy.value
        store.dispatch('activityLogStore/fetchActivityLogs', {
            sort: sort,
            page: currentPage.value,
            perPage: perPage.value,
            q: searchQuery.value,
            user: userFilter.value,
            model: contentTypeFilter.value,
            action: actionFilter.value,
            pagination: true
        }).then(response => {
            const {data} = response.data
            callback(data)
            totalActivityLogs.value = response.data.pagination.total
        }).catch(error => {
            console.log(error)
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Error',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: 'Oops! Something went wrong!',
                },
            })
        })
    }

    const fetchActivityLog = (ctx, callback) => {
        store
            .dispatch('activityLogStore/fetchActivityLog', {id: router.currentRoute.params.activityLog})
            .then(response => {
                const {data} = response.data
                activityLogData.value = data
                isLoading.value = false
            })
            .catch(error => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        text: 'Oops! Something went wrong!',
                    },
                })
                router.push('/activity-log/list');
            })
    }

    const fetchActivityLogTypes = (ctx, callback) => {
        store.dispatch('activityLogStore/fetchActivityLogTypes').then(response => {
            isLoading.value = false
            contentTypeList.value = response.data.contentTypeList
            userList.value = response.data.userList
            actionList.value = response.data.actionList
        }).catch(error => {
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Error',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: 'Oops! Something went wrong!',
                },
            })
            router.push('/activity-log/list');
        })
    }

    return {
        fetchActivityLogs,
        fetchActivityLog,
        fetchActivityLogTypes,
        activityLogData,
        tableColumns,
        perPage,
        currentPage,
        totalActivityLogs,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refActivityLogListTable,
        isLoading,
        refetchData,

        userList,
        contentTypeList,
        actionList,
        userFilter,
        contentTypeFilter,
        actionFilter
    }
}
