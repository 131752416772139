<template>

    <div>
        <b-card no-body class="mb-0">
            <div class="m-2">
                <b-row>
                    <b-col cols="12" md="4" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select v-model="perPage" :options="perPageOptions"
                                  :clearable="false" class="per-page-selector d-inline-block mx-50 ml-1"/>
                    </b-col>
                    <b-col cols="12" md="8">
                        <b-row class="d-flex align-items-center justify-content-end">
                            <b-col cols="12" md="3" class="mb-md-0 mb-2">
                                <v-select v-if="userList" :options="userList" v-model="userFilter"
                                          placeholder="Select User" class="w-100"/>
                            </b-col>
                            <b-col cols="12" md="3" class="mb-md-0 mb-2">
                                <v-select v-if="actionList" :options="actionList" v-model="actionFilter"
                                          placeholder="Select Action" class="w-100"/>
                            </b-col>
                            <b-col cols="12" md="3" class="mb-md-0 mb-2">
                                <v-select v-if="contentTypeList" :options="contentTypeList" v-model="contentTypeFilter"
                                          placeholder="Select Content Type" class="w-100"/>
                            </b-col>
                            <b-col cols="12" md="3">
                                        <b-form-input v-model="searchQuery" class="d-inline-block mr-1"
                                                      placeholder="Search..."/>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </div>
            <b-table ref="refActivityLogListTable" class="position-relative" :items="fetchActivityLogs"
                     responsive
                     :table-class="'build-list-table table dataTable no-footer dtr-column'" striped
                     :fields="tableColumns" primary-key="id" :sort-by.sync="sortBy" show-empty
                     empty-text="No matching records found"
                     :sort-desc.sync="isSortDirDesc">
                <template #table-busy>
                    <div class="text-center my-2">
                        <b-spinner class="d-block mx-auto mb-50"></b-spinner>
                        <strong>Loading...</strong>
                    </div>
                </template>
                <!-- Column: id -->
                <template #cell(id)="data">
                    <span class="align-text-top text-capitalize">{{ data.item.id }}</span>
                </template>

                <!-- Column: Name -->
                <template #cell(user)="data">
                    <span class="align-text-top text-capitalize">{{ data.item.user.name }}</span>
                </template>

                <!-- Column: Name -->
                <template #cell(content_type)="data">
                    <span class="align-text-top text-capitalize">{{ data.item.content_type }}</span>
                </template>

                <!-- Column: Name -->
                <template #cell(content_id)="data">
                    <span class="align-text-top text-capitalize">{{ data.item.content_id }}</span>
                </template>

                <!-- Column: Name -->
                <template #cell(description)="data">
                    <span class="align-text-top text-capitalize">{{ data.item.description }}</span>
                </template>

                <!-- Column: Created at -->
                <template #cell(created_at)="data">
                    <span class="align-text-top text-capitalize">{{ dateFormat(data.item.created_at) }}</span>
                </template>

                <!--Column: Actions -->
                <template #cell(actions)="data">
                    <b-link class="mr-1" v-if="$can('read', 'activityLogList')"
                            @click="getDetailInfo(data.item.id)"
                            v-b-tooltip.hover.top="'Activity Log Detail'">
                        <feather-icon icon="EyeIcon" size="18"/>
                    </b-link>
                </template>
            </b-table>

            <div class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6"
                           class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}
              entries</span>
                    </b-col>
                    <b-col cols="12" sm="6"
                           class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination v-model="currentPage" :total-rows="totalActivityLogs" :per-page="perPage"
                                      first-number
                                      last-number
                                      class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18"/>
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18"/>
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
            <activity-log-list-detail ref="modal" :modalLoading="modalLoading"
                                      :activityLogDetail="activityLogDetail"></activity-log-list-detail>
        </b-card>
    </div>
</template>

<script>
import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination, VBTooltip, BSpinner
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import vSelect from "vue-select";
import useActivityLogApi from "@/composables/useActivityLogApi";
import ActivityLogListDetail from './ActivityLogListDetail.vue'

export default {
    components: {
        BSpinner,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        vSelect,
        ActivityLogListDetail
    },
    directives: {
        'b-tooltip': VBTooltip,
        Ripple,
    },
    data() {
        return {
            modalLoading: true,
            activityLogDetail: null,
        }
    },
    mounted() {
        this.fetchActivityLogTypes()
    },
    setup() {
        const userData = JSON.parse(localStorage.getItem('userData'))
        const {
            fetchActivityLogs,
            fetchActivityLogTypes,
            tableColumns,
            perPage,
            currentPage,
            totalActivityLogs,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refActivityLogListTable,
            refetchData,
            userList,
            contentTypeList,
            actionList,
            userFilter,
            contentTypeFilter,
            actionFilter
        } = useActivityLogApi()

        return {
            fetchActivityLogs,
            fetchActivityLogTypes,
            tableColumns,
            perPage,
            currentPage,
            totalActivityLogs,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refActivityLogListTable,
            refetchData,
            userData,

            userList,
            contentTypeList,
            actionList,

            userFilter,
            contentTypeFilter,
            actionFilter
        }
    },
    methods: {
        dateFormat(date) {
            return moment(date).format('YYYY-MM-DD HH:mm')
        },
        getDetailInfo(id) {
            this.$store.dispatch('activityLogStore/fetchActivityLog', {id})
                .then((response) => {
                    const {data} = response.data
                    this.activityLogDetail = data
                    this.modalLoading = false
                }).catch((errors) => {
                this.modalLoading = false
            })
            this.$root.$emit("bv::show::modal", "modal-primary");
        },
    }
}

</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
